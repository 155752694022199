import React from "react"
import Helmet from "react-helmet"
import SEO from "../components/seo"
import DownloadImage from "../assets/images/download-image.png"

const Download = () => (
  <html>
    <head>
      <SEO
        title="تحميل تطبيق نشرة"
        image={DownloadImage}
        url="https://nshra.com/download"
      ></SEO>
    </head>
    <body>
      <p>
        <a href="javascript:redirectToApp()">Nshra | نشرة</a>
      </p>
      <Helmet script={[{ src: "/scripts/stores-redirect.js" }]}></Helmet>
    </body>
  </html>
)

export default Download
